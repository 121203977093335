<template>
<div>
    <b-row>
        <b-col>
            Välj användare
        </b-col>
        <b-col>
            <b-select :options="users" v-model="userid" value-field="id" label-field="username" text-field="label"></b-select>
        </b-col>
        <b-col cols="12" class="pt-1">
            <Alert v-if="userid <= 0" variant="danger" >Välj en användare</Alert>
        </b-col>
    </b-row>
    <b-row class="mt-2">
        <b-col>
            Välj åtkomst
        </b-col>
        <b-col>
            <b-select v-model="access" :options="accessLevels"></b-select>
        </b-col>
    </b-row>

    <div class="mt-4 text-center">
        <b-button class="m-1" variant="secondary" @click="Cancel()">Avbryt</b-button>
        <b-button class="m-1" variant="success" @click="AddUser()">Lägg till</b-button>
    </div>
</div>
</template>

<script>
import tournamentsenum from "@/helpers/enums/tournaments";

const Alert = () => import("@/components/miscellaneous/Alert");

export default {
    Name: "AddTournamentUser",
    components: {
        Alert
    },
    data() {
        return {
            terrors: [],
            ticks: 1,
            access: 0,
            userid: 0
        }
    },
    computed: {
        users() {
            var items = this.$store.getters["administratorget/users"];
            if (!items)
                return false;
            for (var i = 0; i < items.length; i++) {
                items[i].label = items[i].username + " - " + items[i].email;
            }
            return items;
        },
        accessLevels() {
            var items = tournamentsenum.access;
            var accessitems = [];
            for (var id in items) {
                accessitems.push(items[id]);
            }

            return accessitems;
        }
    },
    methods: {
        AddUser() {
            if (this.userid > 0) {
                this.$emit("select", {
                    userid: this.userid,
                    access: this.access
                });
            }
        },
        Cancel() {
            this.$emit("cancel");
        }
    },
};
</script>
